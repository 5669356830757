import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import Img from "gatsby-image"
import {
    graphql,
    useStaticQuery,
    navigate,
    prefetchPathname,
  } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))



const About = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
      query {
        companyAbout: file(relativePath: { eq: "cedar-company.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

//   cedar-company.jpg
  return (
    <Layout>
      <SEO title="About Us" />
    <Container component="main" maxWidth="md">
        <Typography component="h1" variant="h5">
          About Us
        </Typography>
        <Typography style={{marginTop: '20px'}}>Learn more about Cedar Solar on our main webpage <Link target="_blank" href="https://www.cedarsolar.com/">cedarsolar.com</Link> </Typography>
    
        
        
    </Container>
    </Layout>
  )
}

export default About
